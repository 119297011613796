import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/fuelling/Development/sappenin/ilpv4-dev-website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ilpv4-dev-website"
    }}>{`ILPv4 Dev Website`}</h1>
    <p>{`This website allows you to experience the future of payments using the Interledger Testnet.
See it in action at `}<a parentName="p" {...{
        "href": "https://ilpv4.dev"
      }}>{`https://ilpv4.dev`}</a>{`.`}</p>
    <h2 {...{
      "id": "interledger-background"
    }}>{`Interledger Background`}</h2>
    <p>{`The Interledger Protocol (ILP) provides the foundation for the internet of Value. ILP is an open protocol that enables
sending money in any currency and any amount. The goal is to make sending value as fast, easy, and inexpensive as
sending information across the internet is today.`}</p>
    <p>{`Like routers on today's internet, Interledger connectors route packets of money across independent
payment networks. The open architecture and minimal protocol enable interoperability for any
value transfer system.`}</p>
    <p>{`Interledger is not tied to any one company, blockchain, or currency. We believe that
interoperability is critical to achieving the internet of value and envision a widely available
Interledger network allowing anyone to pay or get paid in the currency of their choice, nearly
instantaneously and at minimal cost.`}</p>
    <p>{`For more information, read more at `}<a parentName="p" {...{
        "href": "https://interledger.org"
      }}>{`interledger.org`}</a>{`.`}</p>
    <h2 {...{
      "id": "how-can-i-contribute"
    }}>{`How can I contribute?`}</h2>
    <p>{`To contribute to the content on this site, consider one of the following:`}</p>
    <ul>
      <li parentName="ul">{`Check out the issues page and see if there are any you can help with!`}</li>
      <li parentName="ul">{`Submit links to add to specific sections that are incomplete, by submitting a pull-request`}</li>
      <li parentName="ul">{`Identify out-of-date information on `}<a parentName="li" {...{
          "href": "https://ilpv4.dev"
        }}>{`https://ilpv4.dev`}</a>{` (or linked to from `}<a parentName="li" {...{
          "href": "https://ilpv4.dev"
        }}>{`https://ilpv4.dev`}</a>{`) and submit a pull-request`}</li>
      <li parentName="ul">{`Suggest ideas for new subpages, new content, or other ways to improve `}<a parentName="li" {...{
          "href": "https://ilpv4.dev"
        }}>{`https://ilpv4.dev`}</a>{` by opening an issue.`}</li>
    </ul>
    <h2 {...{
      "id": "development"
    }}>{`Development`}</h2>
    <h3 {...{
      "id": "unixmac"
    }}>{`Unix/Mac`}</h3>
    <pre><code parentName="pre" {...{}}>{`# In the root folder:
yarn add docz docz-theme-default --dev
yarn
npm install
npm run-script docz:dev
`}</code></pre>
    <h3 {...{
      "id": "windows"
    }}>{`Windows`}</h3>
    <ul>
      <li parentName="ul">{`Download `}<a parentName="li" {...{
          "href": "https://nodejs.org/en/download/"
        }}>{`node.js & npm`}</a></li>
      <li parentName="ul">{`Download `}<a parentName="li" {...{
          "href": "https://yarnpkg.com/en/docs/install#windows-stable"
        }}>{`Yarn`}</a></li>
      <li parentName="ul">{`Download `}<a parentName="li" {...{
          "href": "https://git-scm.com/downloads"
        }}>{`Git Bash`}</a></li>
      <li parentName="ul">{`Download the `}<inlineCode parentName="li">{`master`}</inlineCode>{` branch`}</li>
      <li parentName="ul">{`Navigate to the `}<inlineCode parentName="li">{`/ilpv4-dev-website`}</inlineCode>{` folder`}</li>
      <li parentName="ul">{`Right click and select `}<inlineCode parentName="li">{`Git Bash Here`}</inlineCode></li>
    </ul>
    <p>{`Run the following commands:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install -g yarn
npm install
yarn
npm run-script docz:dev
`}</code></pre>
    <h2 {...{
      "id": "build"
    }}>{`Build`}</h2>
    <pre><code parentName="pre" {...{}}>{`# In the root folder:
npm run-script docz:build
`}</code></pre>
    <p>{`The build should be exported to `}<inlineCode parentName="p">{`/.docz/dist`}</inlineCode>{` which can be deployed to a static host.
We are hosting the site on github pages via the deploy script below.`}</p>
    <h2 {...{
      "id": "deployment"
    }}>{`Deployment`}</h2>
    <pre><code parentName="pre" {...{}}>{`# In the root folder:
./deploy.sh

(note: move the \`.well-known\` folder into the root before deploying)
`}</code></pre>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`Site content is in `}<inlineCode parentName="p">{`/src`}</inlineCode>{` folder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      